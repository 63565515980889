<template>
  <section class="hero">
    <div class="hero-content">
      <h1>Enhance Your Auto Repair Shop Efficiency</h1>
      <p>An all-in-one digital management tool that simplifies processes and saves time.</p>
      <button class="cta-btn" @click="startFreeTrial">Start Your Free Trial</button>
    </div>
  </section>
</template>

<script>
/* eslint-disable vue/multi-word-component-names */
export default {
  name: 'HeroBanner',
  methods: {
    startFreeTrial() {
      window.open(process.env.VUE_APP_REGISTER_URL, '_blank');
    }
  }
};
</script>

<style scoped>
.hero {
  background-image: url('@/assets/auto-mechanic-certification1.jpeg');
  background-size: cover;
  background-position: center;
  padding: 150px 20px;
  text-align: center;
  color: white;
}
.hero-content {
  max-width: 600px;
  margin: 0 auto;
}
h1 {
  font-size: 60px;
  margin-bottom: 20px;
  color: #1dda13;
}
p {
  font-size: 22px;
  margin-bottom: 30px;
}
.cta-btn {
  background-color: var(--primary-color);
  color: #fff;
  padding: 15px 30px;
  border: none;
  cursor: pointer;
}
</style>


