<template>
  <section class="testimonials">
    <h2>What Our Customers Say</h2>
    <div class="carousel">
      <div
        v-for="(testimonial, index) in testimonials"
        :key="index"
        v-show="currentTestimonial === index"
        class="testimonial testimonial-content"
      >
        <p class="message">“{{ testimonial.message }}”</p>
        <p class="customer">
          <img :src="testimonial.avatar" alt="Avatar" class="avatar" /> - {{ testimonial.customer }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable vue/multi-word-component-names */
export default {
  name: 'Testimonials',
  data() {
    return {
      currentTestimonial: 0,
      testimonials: [
        {
          customer: "Bruce Mitchell, Small Repair Shop Owner",
          message: "The ability to manage all my jobs in one place has been a game changer for my business. No more lost paperwork or Excel files!",
          avatar: "https://i.pravatar.cc/100?img=10"
        },
        {
          customer: "Jack Thompson, Auto Service Manager",
          message: "One-click invoice generation has saved me countless hours of admin work. It's so easy to track payments now!",
          avatar: "https://i.pravatar.cc/100?img=11"
        },
        {
          customer: "Mark Wilson, Growing Workshop",
          message: "Managing customer information and sending promotions via SMS has helped me retain clients and increase sales!",
          avatar: "https://i.pravatar.cc/100?img=12"
        },
        {
          customer: "Graham Edwards, Workshop Owner",
          message: "Inventory management used to be a headache, but now I get alerts when stock is low, making my purchasing process much easier.",
          avatar: "https://i.pravatar.cc/100?img=13"
        },
        {
          customer: "Peter Johnson, Auto Shop Owner",
          message: "With the financial overview, I can easily track my shop's income and expenses. It’s a powerful tool for staying on top of the numbers.",
          avatar: "https://i.pravatar.cc/100?img=14"
        }
      ]
    };
  },
  mounted() {
    this.startCarousel();
  },
  methods: {
    startCarousel() {
      setInterval(() => {
        this.currentTestimonial = (this.currentTestimonial + 1) % this.testimonials.length;
      }, 10000); // 每10秒切换一次
    }
  }
};
</script>

<style scoped>
.testimonials {
  padding: 50px 20px;
  text-align: center;
  background-color: #f9f9f9;
}

h2 {
  color: var(--primary-color);
  margin-bottom: 30px;
}

.carousel {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
}

.testimonial-content {
  transition: opacity 1s ease-in-out;
}

.testimonial-content[style*="display: none"] {
  opacity: 0;
}

.message {
  font-size: 20px;
  font-style: italic;
  margin-bottom: 15px;
  font-family: 'Arial', sans-serif;
}

.customer {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
</style>
