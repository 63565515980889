<template>
  <header>
    <nav>
      <img src="@/assets/ash-logo-1.jpg" alt="Auto Service Hub" class="logo" />
      <!-- 汉堡菜单按钮 -->
      <button class="menu-btn" @click="toggleMenu">
        ☰
      </button>
      <div class="nav-items" :class="{ 'show-menu': menuOpen }">
        <ul>
          <li><a href="#features">Features</a></li>
          <li><a href="#pricing">Pricing</a></li>
          <li><a href="#testimonials">Testimonials</a></li>
          <li><a href="#about">About Us</a></li>
          <li><a href="#contact">Contact Us</a></li>
        </ul>
        <button class="login-btn" @click="login">Login</button>
        <button class="cta-btn" @click="startFreeTrial">Start Free Trial</button>
      </div>
    </nav>
  </header>
</template>

<script>
/* eslint-disable vue/multi-word-component-names */
export default {
  name: 'Header',
  data() {
    return {
      menuOpen: false // 控制菜单是否展开
    };
  },
  methods: {
    startFreeTrial() {
      window.open(process.env.VUE_APP_REGISTER_URL, '_blank');
    },
    login() {
      window.open(process.env.VUE_APP_LOGIN_URL, '_blank');
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    }
  }
}
</script>

<style scoped>
header {
  background-color: #fff;
  padding: 20px;
}

nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.logo {
  width: 150px;
  margin-right: auto;
}

/* 汉堡菜单按钮样式 */
.menu-btn {
  display: none;
  font-size: 24px;
  background: var(--primary-color);
  border: none;
  cursor: pointer;
  margin-left: auto;
  border-radius: 5px;
}

.nav-items {
  display: flex;
  align-items: center;
}

nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  text-decoration: none;
  color: #000;
  padding: 10px 15px;
  transition: all 0.3s ease;
}

nav ul li a:hover {
  background-color: var(--primary-color);
  color: white;
  border-radius: 5px;
}

.cta-btn {
  background-color: var(--primary-color);
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-left: 15px;
  transition: background-color 0.3s ease;
  border-radius: 5px;
}

.cta-btn:hover {
  background-color: #1c4b99;
}

.login-btn {
  background-color: white;
  color: var(--primary-color);
  padding: 10px 20px;
  border: 1px solid var(--primary-color);
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;
  border-radius: 5px;
}

.login-btn:hover {
  background-color: var(--primary-color);
  color: white;
  border-color: var(--primary-color);
}

/* 小屏幕适配 */
@media (max-width: 768px) {
  .nav-items {
    display: none;
    position: absolute;
    top: 75px;
    right: 0;
    background-color: #fff;
    width: 90%;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 20px;
  }

  .nav-items.show-menu {
    display: flex;
  }

  nav ul {
    flex-direction: column;
    width: 100%;
  }

  nav ul li {
    margin: 10px 0;
  }

  .menu-btn {
    display: block; /* 显示汉堡菜单按钮 */
  }

  .cta-btn, .login-btn {
    width: 100%;
    text-align: left;
    margin: 10px 0;
  }
}
</style>
