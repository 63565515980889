<template>
  <section class="pricing">
    <h2>Pricing Plans</h2>
    <div class="plans">
      <!-- Starter Plan -->
      <div class="plan starter">
        <h3>Starter Plan</h3>
        <p>Perfect for small repair shops, supporting up to 3 users.</p>
        <p class="price">$49/month or $490/year</p>
        <button class="cta-btn" @click="startFreeTrial">Start Free Trial</button>
      </div>
      
      <!-- Growth Plan -->
      <div class="plan growth">
        <h3>Growth Plan</h3>
        <p>Ideal for growing repair shops, supporting up to 10 users.</p>
        <p class="price">$79/month or $790/year</p>
        <button class="cta-btn" @click="startFreeTrial">Start Free Trial</button>
      </div>
      
      <!-- Premium Plan -->
      <div class="plan premium">
        <h3>Premium Plan</h3>
        <p>For established repair shops, supporting up to 20 users.</p>
        <p class="price">$119/month or $1190/year</p>
        <button class="cta-btn" @click="startFreeTrial">Start Free Trial</button>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable vue/multi-word-component-names */
export default {
  name: 'Pricing',
  methods: {
    startFreeTrial() {
      window.open(process.env.VUE_APP_REGISTER_URL, '_blank');
    }
  }
};
</script>

<style scoped>
.pricing {
  padding: 50px 20px;
  text-align: center;
}

h2 {
  color: var(--primary-color);
  margin-bottom: 30px;
}

.plans {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 大屏幕默认显示三列 */
  gap: 30px;
}

.plan {
  padding: 40px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.plan h3 {
  margin-bottom: 15px;
}

.plan p {
  margin-bottom: 20px;
}

.price {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Starter Plan 特定样式 */
.starter {
  background-color: #f0f8ff; /* 浅蓝色 */
  border: 2px solid #2056AC; /* 主题蓝色边框 */
}

/* Growth Plan 特定样式 */
.growth {
  background-color: #fff3cd; /* 浅黄色 */
  border: 2px solid #ffc107; /* 金黄色边框 */
}

/* Premium Plan 特定样式 */
.premium {
  background-color: #f8d7da; /* 浅粉色 */
  border: 2px solid #dc3545; /* 红色边框 */
}

.cta-btn {
  background-color: var(--primary-color);
  color: white;
  padding: 15px 30px;
  font-size: 18px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-btn:hover {
  background-color: #1c4b99;
}

/* 媒体查询：在屏幕宽度小于 768px 时，一行显示一张卡片 */
@media (max-width: 768px) {
  .plans {
    grid-template-columns: 1fr; /* 小屏幕时显示一列 */
  }
}
</style>
