<template>
  <section class="features">
    <div class="feature">
      <h2>Job Management</h2>
      <p>Manage various repair jobs effortlessly with customizable categories and pricing.</p>
    </div>
    <div class="feature">
      <h2>Customer & Vehicle Management</h2>
      <p>Consolidate customer and vehicle information to enhance service efficiency.</p>
    </div>
    <div class="feature">
      <h2>One-click Invoice and Quote Generation</h2>
      <p>Generate invoices and quotes with just one click, making the process simple and user-friendly.</p>
    </div>
    <div class="feature">
      <h2>Flexible Payment Management</h2>
      <p>Manage payment information with ease, ensuring no unpaid items are missed.</p>
    </div>
    <div class="feature">
      <h2>Inventory Management</h2>
      <p>Manage inventory with features like purchasing, usage tracking, and stock alerts.</p>
    </div>
    <div class="feature">
      <h2>Financial Overview</h2>
      <p>Easily view income and expense summaries with powerful financial tracking features.</p>
    </div>
    <div class="feature">
        <h2>Email & SMS Functionality</h2>
        <p>Built-in email and SMS system with customizable templates. SMS can be used for business promotions.</p>
    </div>
    <div class="feature">
        <h2>Image & Document Storage</h2>
        <p>Store photos and documents for each job, including invoices and quotes, ensuring nothing is ever lost.</p>
    </div>
    <div class="feature">
        <h2>Rental Service Management</h2>
        <p>For workshops offering rental services, easily manage vehicle rentals and related tasks.</p>
    </div>
    <div class="feature">
      <h2>User Roles & Permissions</h2>
      <p>Supports three levels of users: admin, manager, and staff, ensuring security and easy access control.</p>
    </div>

  </section>
</template>

<style scoped>
.features {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 每行2列 */
  gap: 20px;
  padding: 50px 20px;
}
.feature {
  background-color: #f8f9fa;
  padding: 20px;
  text-align: center;
  border-radius: 8px;
}
h2 {
  font-size: 24px;
  margin-bottom: 10px;
}
p {
  font-size: 16px;
}
</style>



<script>
/* eslint-disable vue/multi-word-component-names */
export default {
  name: 'Features',
  // 组件代码...
}
</script>
