<template>
  <section id="contact" class="contact-section">
    <div class="container">
      <h2>Contact Us</h2>
      <p>If you have any questions or need assistance, feel free to reach out to us:</p>
      <form class="contact-form" @submit.prevent="submitForm">
        <div>
          <label for="name">Name</label>
          <input type="text" id="name" v-model="form.name" required />
        </div>
        <div>
          <label for="email">Email</label>
          <input type="email" id="email" v-model="form.email" required />
        </div>
        <div>
          <label for="message">Message</label>
          <textarea id="message" v-model="form.message" required></textarea>
        </div>
        <button type="submit" class="cta-btn" :disabled="isSubmitting">
          {{ isSubmitting ? "Sending..." : "Send Message" }}
        </button>
      </form>

      <!-- 提交状态反馈 -->
      <p v-if="formStatus" :class="statusClass">{{ formStatus }}</p>
    </div>
  </section>
</template>

<script>
import CryptoJS from 'crypto-js';

export default {
  data() {
    return {
      form: {
        name: '',
        email: '',
        message: '',
      },
      formStatus: null,
      isSuccess: null,
      isSubmitting: false, // 用于控制按钮的禁用状态
    };
  },
  computed: {
    statusClass() {
      return this.isSuccess ? 'success' : 'error';
    }
  },
  methods: {
    async submitForm() {
      this.isSubmitting = true; // 禁用按钮
      const formData = JSON.stringify({
        name: this.form.name,
        email: this.form.email,
        message: this.form.message
      });

      // 生成 token
      const secretKey = process.env.VUE_APP_SECURITY_KEY;
      const toEncrypt = this.form.name + this.form.email+this.form.message;
      const token = CryptoJS.HmacSHA256(toEncrypt, secretKey).toString();

      try {
        const response = await fetch(process.env.VUE_APP_MESSAGE_API_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            data: formData,
            token: token,
          })
        });

        if (response.ok) {
          this.formStatus = 'Message sent successfully!';
          this.isSuccess = true;
        } else {
          this.formStatus = 'Failed to send message. Please try again. Use English only. Only 1 message in 3 minutes';
          this.isSuccess = false;
        }
      } catch (error) {
        this.formStatus = 'An error occurred. Please try again later.';
        this.isSuccess = false;
      } finally {
        this.isSubmitting = false; // 请求完成后重新启用按钮
        this.resetForm();
      }
    },
    resetForm() {
      this.form.name = '';
      this.form.email = '';
      this.form.message = '';
    },
  },
};
</script>


<style scoped>
.contact-section {
  padding: 50px 20px;
  background-color: #f9f9f9;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

h2 {
  color: var(--primary-color);
  margin-bottom: 20px;
}

.contact-info {
  margin-bottom: 30px;
  text-align: left;
}

.contact-info p {
  margin: 10px 0;
}

.contact-form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
}

.contact-form label {
  text-align: left; /* 左对齐 */
  font-size: 14px;
  margin-bottom: 5px;
  display: block; /* 确保 label 占据一整行 */
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.contact-form textarea {
  height: 100px;
  resize: vertical;
}

.cta-btn {
  background-color: var(--primary-color);
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.cta-btn:hover {
  background-color: #1c4b99;
}

.success {
  margin-top: 20px;
  font-size: 16px;
  color: green; /* 成功消息为绿色 */
}

.error {
  margin-top: 20px;
  font-size: 16px;
  color: red; /* 错误消息为红色 */
}
</style>
