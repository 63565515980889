<template>
  <div id="app">
    <Header />
    <HeroBanner />
    <section id="features">
      <Features />
    </section>
    <section id="pricing">
      <Pricing />
    </section>
    <section id="testimonials">
      <Testimonials />
    </section>
    <section id="about">
      <!-- 假设这里是 "关于我们" 内容 -->
    </section>
    <section id="contact">
      <ContactUs />
  </section>

    <Footer />
  </div>
</template>


<script>
import Header from './components/Header.vue';
import HeroBanner from './components/HeroBanner.vue';
import Features from './components/Features.vue';
import Testimonials from './components/Testimonials.vue';
import Pricing from './components/Pricing.vue';
import Footer from './components/Footer.vue';
import ContactUs from './components/ContactUs.vue';


export default {
  name: 'App',
  components: {
    Header,
    HeroBanner,
    Features,
    Testimonials,
    Pricing,
    Footer,
    ContactUs,
  },
}
</script>

<style>
/* 你可以在这里添加全局样式 */
</style>
