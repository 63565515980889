<template>
  <footer>
    <p>&copy; 2021 Ezdata Software. All Rights Reserved.</p>
    <p>Auto Service Hub is powered by Ezdata Software - ABN:399 562 586 44</p>
  </footer>
</template>

<style scoped>
footer {
  background-color: #343a40;
  color: white;
  text-align: center;
  padding: 20px;
}
</style>


<script>
/* eslint-disable vue/multi-word-component-names */
export default {
  name: 'Footer',
  // 组件代码...
}
</script>